'use client'
import { useState } from "react";
import GenericLinkButton from "@/components/generic/GenericLinkButton";
import Image from "next/image";
import Icon from "@/components/icon/Icon";

export default function SolutionItem({
	                                     title,
	                                     description,
	                                     image,
	                                     href,
	                                     buttonText
                                     }: SolutionItemProps) {
	const [imageSrc, setImageSrc] = useState(image);

	return (
		<div
			className="group/item flex-col gap-3 md:gap-4 p-5 w-full h-full items-center text-center flex justify-around border-1 border-white transition duration-500 hover:cursor-pointer xl:hover:shadow-solution-item xl:hover:bg-white xl:hover:border-[#eee] rounded-xl"
			onMouseEnter={() => {
				const image = imageSrc.split("/").slice(0, -1).join("/") + "/hover/" + imageSrc.split("/").slice(-1);
				setImageSrc(image);
			}}
			onMouseLeave={() => setImageSrc(image)}
		>
			<a className={"relative"} href={href}>
				<Image
					src={imageSrc}
					alt={title}
					width={466}
					height={262}
					style={{ width: 'auto', height: 'auto' }}
					className={"rounded-lg md:w-[290px] md:h-[163px] xl:w-[466px] xl:h-[262px] transition ease-in duration-500"}
				/>
				<div className={"w-10 h-10 md:w-7 md:h-7 xl:w-10 xl:h-10 absolute right-0 bottom-0 border-1 border-[#eee] rounded-lg bg-white flex items-center justify-center shadow-solution-item-button"}>
					<Icon icon={"plus"} size={14} className={"w-[14px] h-[14px] md:w-[10px] md:h-[10px]  xl:w-[14px] xl:h-[14px]"} />
				</div>
			</a>
			<div className={"gap-1 md:gap-2 flex items-center flex-col max-w-[466px] md:w-[290px] xl:w-[466px]"}>
				<p className={"text-sm md:text-base xl:text-xl font-normal text-text_dark"}>
					{title}
				</p>
				<p className={"text-xs md:text-sm xl:text-base font-light text-text_light"}>
					{description}
				</p>
				<nav>
					<GenericLinkButton href={href} title={buttonText} icon={"learn_more_icon"} className={"group-hover/item:underline"} />
				</nav>
			</div>
		</div>
	);
}

export interface SolutionItemProps {
	title: string;
	description: string;
	image: string;
	href: string;
	buttonText?: string;
}
