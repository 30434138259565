import {Blog} from "@/domain/Blog";
import {GenericContainer} from "@/components/generic/GenericContainer";
import BlogItem from "@/components/blog/BlogItem";
import {GenericTabMenu} from "@/components/generic/GenericTabMenu";
import CustomPaginator from "@/components/blog/pagination/CustomPaginator";
import classNames from "classnames";
import {useTranslations} from "next-intl";
import {ReactNode} from "react";

export default function BlogTabComponent({
	                                         blogs,
	                                         titles,
	                                         page,
	                                         selectedResource = titles[0],
	                                         totalBlogCount,
	                                         itemsPerPage,
	                                         setPage,
	                                         setSelectedResource,
	                                         loading,
	                                         containerClassName,
	                                         disablePagination,
	                                         customBlogItem
                                         }: BlogTabComponentProps) {
	const t = useTranslations('');
	const onChange = (index: number) => {
		setSelectedResource(titles[index]);
	}

	return (
		<GenericContainer
			className={classNames("bg-white text-center flex flex-col items-center justify-center gap-[52px] scroll-mt-10", containerClassName)}
			id={"tab"}>
			{titles.length > 1 && <GenericTabMenu titles={titles}
			                                      initialTab={titles.indexOf(selectedResource)}
			                                      onChange={onChange}
			/>}
			<GenericContainer
				className={classNames("xl:!grid-cols-3 md:!grid-cols-2 grid !gap-6 md:!px-0 md:!py-0 max-w-container", loading && "!grid-cols-none md:!grid-cols-none xl:!grid-cols-none  min-h-[996px] xl:min-h-[664px]")}>
				{blogs && blogs.length > 0 && blogs.map((blog) => {
					if(customBlogItem) return customBlogItem(blog)
					return <BlogItem blog={blog} key={blog.name}/>
				})}
				{loading && <div
					className={"flex flex-row text-center items-center justify-center align-middle min-w-full w-full h-full"}>
					<p className={"text-text_dark text-4xl font-medium text-center justify-center"}>
						{t("loading")}
					</p>
				</div>}
			</GenericContainer>
			{!disablePagination &&
				<CustomPaginator fixed rows={itemsPerPage} totalRecords={totalBlogCount} rowsPerPageOptions={[itemsPerPage]}
				                 loading={loading}
				                 currentPage={page} onPageChange={(event) => {
					setPage(event.page)
				}}/>}
		</GenericContainer>
	)
}

export interface BlogTabComponentProps {
	selectedResource: string;
	setSelectedResource: (selectedResource: string) => void;
	blogs: Blog[] | undefined;
	setBlogs: (blogs: Blog[]) => void;
	page: number;
	setPage: (page: number) => void;
	titles: string[];
	itemsPerPage: number;
	totalBlogCount: number;
	loading: boolean;
	containerClassName?: string;
	disablePagination?: boolean;
	customBlogItem?: (blog: Blog) => ReactNode;
}
