import Icon from "@/components/icon/Icon";
import classNames from "classnames";

export default function BrandCommentItem({comment, className}: BrandCommentItemProps) {
	return (
		<div
			className={`text-text_dark bg-white text-xl xl:text-2xl flex flex-col border-[#eee] rounded-xl border-1 gap-3 md:w-[414px] xl:w-[766px] md:h-[254px] text-start px-8 py-10 ${comment.className} ${className}`}>
			<div className={"flex justify-between items-start text-base text-[#666]"}>
				<p className={"font-light text-base"}>{comment.authorDescription + ", " + comment.author}</p>
				<div className={"flex flex-row align-top"}>
					{[1, 2, 3, 4, 5].map((star) => {
						return (
							<Icon key={star} icon={"yildiz_icon"}
							      className={classNames("w-4 h-4", star <= Number(comment.stars) ? "!fill-formica" : "!fill-[#EAEAEA]")}/>
						)
					})}
				</div>
			</div>
			<p>{comment.comment}</p>
		</div>
	)
}

export interface BrandComment {
	comment: string,
	author: string,
	stars: string,
	authorDescription: string,
	brandImage: string,
	brandClassName?: string,
	className?: string,
	brandBackgroundName?: string,
	metrics?: Array<{
		value: string,
		suffix: string,
		description: string
	}>
}

export interface BrandCommentItemProps {
	comment: BrandComment
	className?: string
}
