'use client';
import {GenericContainer} from "@/components/generic/GenericContainer";
import {GenericTitle} from "@/components/generic/GenericTitle";
import {GenericTabMenu} from "@/components/generic/GenericTabMenu";
import React from "react";
import {IndustryFeatureComponentProps} from "@/components/feature/IndustryFeatureComponent";
import {useMessages, useTranslations} from "next-intl";
import IndustryViewComponent, {IndustryViewComponentProps} from "@/components/industry/IndustryViewComponent";

export function IndustryComponent() {
	const [activeTab, setActiveTab] = React.useState(0);
	const t = useTranslations('');
	const messages = useMessages();

	const industries: IndustryViewComponentProps[] = [
		{
			topTitle: t("industries.title"),
			title: t("industries.banking.title"),
			icon: "banking",
			description: t("industries.banking.description"),
			button2Title: t("industries.banking.button2Title"),
			button2Href: "/industries/banking",
			containerClassName: "bg-banking-model-mobile-bg md:bg-banking-model-tablet-bg xl:bg-banking-model-bg",
			metrics: {
				title: t("industries.banking.metrics.title"),
				suffix: t("industries.banking.metrics.suffix"),
				prefix: t("industries.banking.metrics.prefix"),
				value: t("industries.banking.metrics.value")
			},
			marqueeItems: messages.industries["banking"].marqueeItems
		},
		{
			topTitle: t("industries.title"),
			title: t("industries.paymentFacilitator.title"),
			icon: "payment-faciliator",
			description: t("industries.paymentFacilitator.description"),
			button2Title: t("industries.paymentFacilitator.button2Title"),
			button2Href: "/industries/payment-facilitator",
			containerClassName: "bg-payment-model-mobile-bg md:bg-payment-model-tablet-bg xl:bg-payment-model-bg",
			metrics: {
				title: t("industries.paymentFacilitator.metrics.title"),
				suffix: t("industries.paymentFacilitator.metrics.suffix"),
				prefix: t("industries.paymentFacilitator.metrics.prefix"),
				value: t("industries.paymentFacilitator.metrics.value")
			},
			marqueeItems: messages.industries["paymentFacilitator"].marqueeItems
		},
		{
			topTitle: t("industries.title"),
			title: t("industries.eCommerce.title"),
			icon: "e-commerce",
			description: t("industries.eCommerce.description"),
			button2Title: t("industries.eCommerce.button2Title"),
			button2Href: "/industries/e-commerce",
			containerClassName: "bg-ecommerce-model-mobile-bg md:bg-ecommerce-model-tablet-bg xl:bg-ecommerce-model-bg",
			metrics: {
				title: t("industries.eCommerce.metrics.title"),
				suffix: t("industries.eCommerce.metrics.suffix"),
				prefix: t("industries.eCommerce.metrics.prefix"),
				value: t("industries.eCommerce.metrics.value")
			},
			marqueeItems: messages.industries["eCommerce"].marqueeItems
		},
		{
			topTitle: t("industries.title"),
			title: t("industries.travelAndTicketing.title"),
			icon: "travel-and-ticketing",
			description: t("industries.travelAndTicketing.description"),
			button2Title: t("industries.travelAndTicketing.button2Title"),
			button2Href: "/industries/travel-and-ticketing",
			containerClassName: "bg-travel-ticketing-model-mobile-bg md:bg-travel-ticketing-model-tablet-bg xl:bg-travel-ticketing-model-bg",
			metrics: {
				title: t("industries.travelAndTicketing.metrics.title"),
				suffix: t("industries.travelAndTicketing.metrics.suffix"),
				prefix: t("industries.travelAndTicketing.metrics.prefix"),
				value: t("industries.travelAndTicketing.metrics.value")
			},
			marqueeItems: messages.industries["travelAndTicketing"].marqueeItems
		},
		{
			topTitle: t("industries.title"),
			title: t("industries.bnpl.title"),
			icon: "bnpl",
			description: t("industries.bnpl.description"),
			button2Title: t("industries.bnpl.button2Title"),
			button2Href: "/industries/bnpl",
			containerClassName: "bg-bnpl-model-mobile-bg md:bg-bnpl-model-tablet-bg xl:bg-bnpl-model-bg",
			metrics: {
				title: t("industries.bnpl.metrics.title"),
				suffix: t("industries.bnpl.metrics.suffix"),
				prefix: t("industries.bnpl.metrics.prefix"),
				value: t("industries.bnpl.metrics.value")
			},
			marqueeItems: messages.industries["bnpl"].marqueeItems
		}
	];


	const onActiveTabChange = (index: number) => {
		setActiveTab(index);
	};

	return (
		<GenericContainer className={"flex flex-col justify-center text-center md:!px-0 h-[790px] bg-formica_light"}>
			<GenericTitle containerClassName={"h-[74px]"} title={t("industries.title")}
			              description={t("industries.description")}
			              descriptionClassName={"whitespace-pre-wrap xl:whitespace-normal"}/>
			<GenericContainer className={"!px-0 !py-0 !pt-[52px] flex flex-col text-start justify-center items-center"}>
				<GenericTabMenu titles={industries.map((item: IndustryFeatureComponentProps) => item.title || "")}
				                icons={industries.map((item: IndustryFeatureComponentProps) => item.icon)}
				                onChange={onActiveTabChange}/>
				<IndustryViewComponent
					{...industries[activeTab]}
					cardView={true}
				/>
			</GenericContainer>
		</GenericContainer>
	);
}
