'use client'
import {GenericContainer} from "@/components/generic/GenericContainer";
import React from "react";
import GenericMetricCard, {GenericMetricCardProps} from "@/components/generic/GenericMetricCard";
import IndustryMarquee from "@/components/industry/IndustryMarquee";
import {IndustryMarqueeItemProps} from "@/components/industry/IndustryMarqueeItem";
import IndustryFeatureComponent, {IndustryFeatureComponentProps} from "@/components/feature/IndustryFeatureComponent";

export default function IndustryViewComponent({
	                                              image,
	                                              topTitle,
	                                              title,
	                                              subtitle,
	                                              description,
	                                              button1Title,
	                                              button2Title,
	                                              button2Href,
	                                              button1Href,
	                                              reverse,
	                                              containerClassName,
	                                              cardView,
	                                              customChildren,
	                                              metrics,
	                                              marqueeItems = []
                                              }: IndustryViewComponentProps) {
	const containerRef = React.useRef(null);

	const rightMarqueeItems = marqueeItems?.slice().reverse();
	return (
		<GenericContainer
			className={"relative justify-center items-center xl:items-start flex flex-col md:flex-col xl:flex-row !py-0 !px-0 !pt-5 md:!pt-5 xl:!pt-5 xl:!px-0"}>
			<IndustryFeatureComponent title={title} topTitle={topTitle} subtitle={subtitle} description={description}
			                          button1Title={button1Title} button2Title={button2Title} button2Href={button2Href}
			                          button1Href={button1Href} reverse={reverse} containerClassName={containerClassName}
			                          cardView={cardView} customChildren={customChildren} image={image}/>
			<div
				className={"relative w-full min-w-[335px] max-w-[335px] md:max-w-[688px] md:w-[688px] md:min-w-[688px] xl:min-w-[260px] xl:w-[260px] xl:pl-5 flex items-center xl:items-start flex-col-reverse md:flex-col-reverse xl:flex-col gap-y-2 md:gap-y-4 z-10"}
				ref={containerRef}>
				<GenericMetricCard metrics={metrics}/>
				<div
					className={"hidden xl:flex relative flex-row z-0 left-[-140px] top-[0px] bg-marquee pb-0.5 pl-2 rounded-tl-2xl gap-x-3"}>
					<IndustryMarquee items={marqueeItems} containerRef={containerRef} containerKey={title}/>
					<IndustryMarquee items={rightMarqueeItems} containerRef={containerRef} containerKey={title} reverse/>
				</div>
				<div
					className={"flex justify-end w-full -mt-[60px] md:-mt-[80px] md:mr-20 md:!w-[768px] md:!min-w-[768px] xl:hidden flex-row z-0 bg-marquee pb-0.5 pl-5 rounded-tl-2xl gap-x-3"}>
					<IndustryMarquee items={marqueeItems}
					                 containerKey={title}
					                 containerRef={containerRef}/>
				</div>
			</div>
		</GenericContainer>
	)

}

export interface IndustryViewComponentProps extends IndustryFeatureComponentProps, GenericMetricCardProps {
	marqueeItems?: IndustryMarqueeItemProps[];
}
