"use client"
import Image from "next/image";
import {GenericContainer} from "@/components/generic/GenericContainer";
import React from "react";
import GenericButton from "@/components/generic/GenericButton";
import classNames from "classnames";
import FeaturePopupComponent, {FeaturePopupComponentProps} from "@/components/feature/FeaturePopupComponent";
import HomepageFeatureComponent from "@/components/feature/HomepageFeatureComponent";
import {useTranslations} from "next-intl";

export default function HomepageFeatureContainer() {
	const t = useTranslations('');

	const popUps: FeaturePopupComponentProps[] = [
		{
			component: <Image src={"/features/popups/Quick_Onboarding_Items.webp"}

			                  className={"w-[98px] md:w-[118px] xl:w-[177px]"} width={177} height={0} alt={"Popup"}/>,
			x: 105,
			mobileX: 50,
			tabletX: 60,
			y: 440,
			mobileY: 200,
			tabletY: 240,
		},
		{
			component: <Image src={"/features/popups/Quick_Onboarding_Login.webp"}

			                  className={"w-[130px] md:w-[154px] xl:w-[238px]"} width={238} height={0} alt={"Popup"}/>,
			x: 280,
			mobileX: 155,
			tabletX: 180,
			y: 280,
			mobileY: 110,
			tabletY: 140,
		}
	];

	return <HomepageFeatureComponent title={t("features.quickOnboarding.title")}
	                                 image={"/features/Home_Page_Quick_Onboarding.webp"}
	                                 description={t("features.quickOnboarding.description")}
	                                 variant={"blue"}
	                                 button1Title={t("features.quickOnboarding.buttonTitle")}
	                                 button1Href={"#contact-us"}
	                                 popUps={popUps}
	/>
}
