import Icon from "@/components/icon/Icon";

export default function GenericLinkButton({href, title, icon, className, disableIcon}: GenericButtonProps) {
	return (
		<a className={`text-xs md:text-sm xl:text-base font-normal gap-1 inline-flex text-formica items-center ${className} hover:underline`} href={href}>
			{title || "Learn more"}
			{//TODO burasındaki icon ismi değiştirilecek
				}
				{
					!disableIcon &&
					<Icon icon={icon || "learn_more_icon" } width={16} className={"fill-formica pt-1"} />
				}
		</a>
	)
}

interface GenericButtonProps {
	href?: string,
	title?: string,
	icon?: string,
	className?: string
	disableIcon?: boolean
}
