import {Blog} from "@/domain/Blog";

export class BlogService {

	private readonly BASE_URL;

	constructor(BASE_URL?: string) {
		this.BASE_URL = BASE_URL || process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:3000";
	}
	public async getById(id: string): Promise<Blog> {
		return fetch(`${this.BASE_URL}/api/blog/${id}`).then(res => res.json())
			.catch((error) => console.log(error));
	}

	public async getBySlug(slug: string): Promise<Blog> {
		return fetch(`${this.BASE_URL}/api/blog/slugs/${slug}`).then(res => {
			return res.json();
		})
	}

	public async getByResource(resource: string, pagination?: {skip?: number, take?: number}): Promise<Blog[]> {
		const url = new URL(`${this.BASE_URL}/api/blog/resources/${resource}`);
		url.searchParams.append("skip", String(pagination?.skip) || undefined);
		url.searchParams.append("take", String(pagination?.take) || undefined);
		return fetch(url).then(res => {
			return res.json();
		}).catch((error) => console.log("error blog service", error));
	}

	public async getPreviewByResource(resource: string, pagination?: {skip?: number, take?: number}): Promise<Blog[]> {
		const url = new URL(`${this.BASE_URL}/api/blog/resources/${resource}/preview`);
		url.searchParams.append("skip", String(pagination?.skip) || undefined);
		url.searchParams.append("take", String(pagination?.take) || undefined);
		return fetch(url).then(res => {
			return res.json();
		}).catch((error) => console.log("error blog service", error));
	}

	public async getCountByResource(resource: string): Promise<number> {
		return fetch(`${this.BASE_URL}/api/blog/resources/${resource}/count`).then(res => res.json())
			.catch((error) => console.log(error));
	}
	public async getAllPublished(): Promise<Blog[]> {
		return fetch(`${this.BASE_URL}/api/blog/published`).then(res => res.json())
			.catch((error) => console.log(error));
	}
}
