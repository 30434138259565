'use client'
import React, {useEffect} from "react";
import Icon from "@/components/icon/Icon";
import {motion} from "framer-motion";
import {useWindowSize} from "@/util/PageUtil";

export function GenericTabMenu({titles, icons, onChange, initialTab}: GenericTabMenuProps) {
	const [activeTab, setActiveTab] = React.useState(0);
	const {width} = useWindowSize();

	useEffect(() => {
		if (initialTab) setActiveTab(initialTab);
	}, [initialTab]);

	const changeActiveTab = (index: number) => {
		setActiveTab(index);
		if (onChange) onChange(index);
	}
	return (
		<div className={"flex flex-row gap-1 p-1 rounded-4xl bg-white border-1/2 border-[#e8e8e8] w-fit"}>
			{titles.map((title, index) => {
				return (
					<motion.div
						onClick={() => changeActiveTab(index)}
						key={index}
						transition={{type: "spring", bounce: 0.2, duration: 0.6}}
						animate={{color: activeTab == index ? "#fff" : "rgb(51 51 51 / 0.4)"}}
						className={`relative flex flex-col min-w-[60px] justify-center text-center gap-2 py-2 px-4 cursor-pointer rounded-4xl`}>
						<span
							className={`flex align-middle z-20 items-center justify-center text-sm font-light`}>
							{width ? width < 768 && icons && icons[index] ?
								<Icon icon={icons[index] as string} width={16}/> : title : <> </>}
						</span>
						{index == activeTab &&
							<motion.div
								layoutId="bubble"
								className="absolute inset-0 p-0.5 z-10 bg-blue-button-border-gradient rounded-full"
								transition={{type: "spring", bounce: 0.2, duration: 0.6}}
							>
								<motion.div
									className={"bg-blue-button-gradient w-full h-full rounded-full"}
								/>
							</motion.div>
						}
					</motion.div>
				);
			})}
		</div>
	);

}

export interface GenericTabMenuProps {
	titles: string[],
	icons?: (string | undefined)[],
	onChange?: (index: number) => void,
	initialTab?: number
}
