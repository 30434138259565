"use client"
import BlogTabComponent from "@/components/blog/BlogTabComponent";
import {useEffect, useState} from "react";
import {Blog} from "@/domain/Blog";
import {BlogService} from "@/service/BlogService";
import BlogItem from "@/components/blog/BlogItemV2";
import {GenericContainer} from "@/components/generic/GenericContainer";
import {GenericTitle} from "@/components/generic/GenericTitle";
import {useTranslations} from "next-intl";

const blogService = new BlogService();

export default function HomepageBlogContainer() {
	const t = useTranslations('');
	const titles = ["News & Updates"];
	const [blogs, setBlogs] = useState<Blog[]>();
	const [page, setPage] = useState<number>(1);
	const [totalBlogCount, setTotalBlogCount] = useState<number>(0);
	const [selectedResource, setSelectedResource] = useState<string>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const itemsPerPage = 6;

	const getBlogs = async (selectedResource: string, page: number) => {
		if (!selectedResource) return;
		setLoading(true);
		setBlogs(undefined);

		const promises = [
			blogService.getPreviewByResource("News & Updates", { skip: 0, take: 1 }),
			blogService.getPreviewByResource("Fraud", { skip: 0, take: 2 }),
			blogService.getPreviewByResource("AML & KYC", { skip: 0, take: 2 }),
			blogService.getPreviewByResource("Artificial Intelligence", { skip: 0, take: 1 })
		];

		const results = await Promise.all(promises);
		const combinedBlogs = results.flat().slice(0, itemsPerPage);

		setBlogs(combinedBlogs);
		setLoading(false);
	};

	const onSelectedResourceChange = () => {
		if (!selectedResource) return;
		else {
			blogService.getCountByResource(selectedResource).then((count) => {
				setTotalBlogCount(count);
			});
		}
		getBlogs(selectedResource, page);
	};

	useEffect(() => {
		setSelectedResource(titles[0]);
		setPage(1);
		getBlogs(titles[0], 1);
	}, []);

	useEffect(() => {
		onSelectedResourceChange();
	}, [selectedResource, page]);

	return (
		<GenericContainer className={"bg-white hidden xl:grid justify-center text-center gap-7 xl:gap-[52px]"}>
			<GenericTitle title={t("blog.homepage.title")} description={t("blog.homepage.description")}/>
			<GenericContainer className={"!px-0 !py-0 max-w-container"}>
				<BlogTabComponent
					containerClassName={"md:!px-0 xl:!px-0 !px-0 !py-0 xl:!py-0 "}
					titles={titles}
					selectedResource={selectedResource}
					setSelectedResource={setSelectedResource}
					blogs={blogs}
					setBlogs={setBlogs}
					page={page}
					setPage={setPage}
					itemsPerPage={itemsPerPage}
					totalBlogCount={totalBlogCount}
					loading={loading}
					disablePagination={true}
					customBlogItem={(blog) => <BlogItem blog={blog}/>}
				/>
			</GenericContainer>
		</GenericContainer>
	)
}
