import {GenericContainer} from "@/components/generic/GenericContainer";
import {GenericTitle} from "@/components/generic/GenericTitle";
import {useTranslations} from "next-intl";

export function HepsiburadaComponent() {
	const t = useTranslations('');

	return (
		<GenericContainer className={"bg-white justify-center text-center grid gap-10 md:gap-[52px]"}>
			<GenericTitle title={t("hepsiburada.title")} description={t("hepsiburada.description")} descriptionClassName={"whitespace-normal md:whitespace-pre-wrap xl:whitespace-normal"}/>
			<GenericContainer
				className={"!gap-6 md:!px-5 md:!py-5 !px-6 !py-6 flex xl:flex-col flex-col justify-center items-center max-w-container shadow-video-card rounded-xl"}>
				<div className={"flex flex-col justify-center min-w-full w-full"}>
					<iframe src="https://www.youtube-nocookie.com/embed/t-BnwIkQrw4?si=haorQ-_Arl8-xcuo&amp;controls=0"
					        title="Formica Customer Story - @hepsiburada" frameBorder="0"
					        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					        className={"min-w-full rounded-lg aspect-video xl:rounded-lg"}
					        allowFullScreen/>
				</div>
				<div className={"grid gap-y-3 px-0 md:pl-7 xl:px-7"}>
					<div
						className={"text-text_dark font-semibold text-base md:text-2xl xl:text-2.5xl text-left whitespace-normal md:whitespace-pre-wrap xl:whitespace-normal"}>
						<div>
							<p>
								{`${t("hepsiburada.authorDescription")}\n- ${t("hepsiburada.author")}`}
							</p>
						</div>
					</div>
					<div className={"text-text_light font-normal text-xs md:text-base xl:text-xl bg-white text-left"}>
						<p>
							{t("hepsiburada.quote")}
						</p>
					</div>
				</div>
			</GenericContainer>
		</GenericContainer>
	)
}
