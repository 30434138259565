"use client"
import Image from "next/image";
import {GenericContainer} from "@/components/generic/GenericContainer";
import React from "react";
import GenericButton from "@/components/generic/GenericButton";
import classNames from "classnames";
import FeaturePopupComponent, {FeaturePopupComponentProps} from "@/components/feature/FeaturePopupComponent";

export default function HomepageFeatureComponent({
	                                                 image,
	                                                 topTitle,
	                                                 title,
	                                                 subtitle,
	                                                 description,
	                                                 button1Title,
	                                                 button2Title,
	                                                 button2Href,
	                                                 button1Href,
	                                                 reverse = false,
	                                                 containerClassName,
	                                                 customChildren,
	                                                 variant = "white",
	                                                 imageWidth = 615,
	                                                 tabletImageWidth = 400,
	                                                 mobileImageWidth = 335,
	                                                 popUps = []
                                                 }: FeatureComponentProps) {
	const imageRef = React.useRef(null);
	return (
		<GenericContainer
			className={classNames(
				variant == "blue" ? "bg-company-video-bg bg-no-repeat bg-100% " : "bg-white",
				`md:!py-0 !xl:py-0 min-h-max flex text-center md:text-start justify-center items-center gap-1 xl:gap-0 xl:gap-x-12`,
				"flex-col-reverse md:flex-row",
				reverse ? `flex-row-reverse xl:flex-row-reverse !pl-[0px]` : `md:!pl-[${imageWidth >= 560 ? imageWidth - 560 : 0}px] md:!pr-0`,
				containerClassName && containerClassName
			)}
		>
			<div
				className={classNames("z-10 flex flex-col pt-5 md:pt-0 md:max-w-[365px] xl:max-w-[480px] justify-start gap-2 md:gap-4 xl:gap-0 items-center md:items-start")}
			>
				<div className={"flex flex-col xl:mb-5 gap-y-2 md:gap-y-4 xl:gap-y-5"}>
					{topTitle && <p className={"text-lg pb-3 font-normal text-formica"}>{topTitle}</p>}
					{title && <p className={"text-2xl md:text-2.5xl xl:text-4.5xl font-semibold text-formica_dark whitespace-pre-wrap md:whitespace-normal"}>{title}</p>}
					{subtitle && <p className={"text-xl xl:text-2xl font-light text-text_dark"}>{subtitle}</p>}
					{description && (
						<p className={classNames("text-text_light font-light text-xs md:text-base xl:text-lg")}>{description}</p>
					)}
				</div>
				<div className={"flex gap-x-4"}>
					{button1Title && button1Href && (
						<GenericButton
							href={button1Href}
							title={button1Title}
							componentSize={"sm"}
							icon={"learn_more_icon"}
							variant={"gradientBlue"}
						/>
					)}
					{button2Title && button2Href && (
						<GenericButton icon={"learn_more_icon"} href={button2Href} title={button2Title} variant={"gradientBlue"}/>
					)}
				</div>
				{customChildren && customChildren}
			</div>
			{image && (
				<div className={`relative flex items-center justify-end flex-col md:h-[344px] xl:h-[600px] xl:w-[${imageWidth}px] md:w-[${tabletImageWidth}px] w-[${mobileImageWidth}px]`} ref={imageRef}>
					<Image src={image} alt={title || "Feature Image"}

					       width={340}
					       height={0}
					       className={`z-0 w-[194px] md:w-[209px] xl:w-[340px] md:h-[344px] xl:h-[560px]`}
					/>
					{popUps?.map((popUp, index) => (
						<FeaturePopupComponent key={index} id={index} {...popUp} containerRef={imageRef}/>
					))}
				</div>
			)}
		</GenericContainer>
	);
}

export interface FeatureComponentProps {
	image?: string;
	imageWidth?: number;
	mobileImageWidth?: number;
	tabletImageWidth?: number;
	topTitle?: string;
	title?: string;
	icon?: string;
	subtitle?: string;
	description?: string;
	button1Title?: string;
	button1Href?: string;
	button2Title?: string;
	button2Href?: string;
	reverse?: boolean;
	containerClassName?: string;
	key?: string | number;
	customChildren?: React.ReactNode;
	variant?: "blue" | "white";
	popUps?: FeaturePopupComponentProps[];
}
