"use client"
import {SolutionComponent} from "@/components/solutions/SolutionComponent";
import HeaderContainer from "@/components/header/HeaderContainer";
import HeroComponent from "@/components/hero/HeroComponent";
import {HepsiburadaComponent} from "@/components/hepsiburada/HepsiburadaComponentV2";
import {TryNowComponent} from "@/components/try-now/TryNowComponent";
import {IndustryComponent} from "@/components/industry/IndustryComponent";
import ContactUsComponent from "@/components/contact-us/ContactUsComponentV2";
import FooterComponent from "@/components/footer/FooterComponent";
import {BrandCommentsContainer} from "@/components/brand-comments/BrandCommentsContainer";
import {brandName} from "@/components/hero/HeroMarqueeWall";
import {useTranslations} from "next-intl";
import {BrandComment} from "@/components/brand-comments/BrandCommentItemV2";
import HomepageBlogContainer from "@/components/blog/homepage/HomepageBlogContainer";
import {AmlKycComponent} from "@/components/aml-kyc/AmlKycComponent";
import {FirstGlanceComponent} from "@/components/first-glance/FirstGlanceComponentV2";
import HomepageFeatureContainer from "@/components/feature/HomepageFeatureContainer";

export default function Homepage() {
	const t = useTranslations('');
	const commentNames = ["akode", "hepsiburada", "anadolubank", "unitedpayment", "pazarama"];

	const comments = commentNames.map((name) => {
		let commentObject: BrandComment = {
			comment: t(`homepage.comments.${name}.comment`),
			author: t(`homepage.comments.${name}.author`),
			stars: t(`homepage.comments.${name}.stars`),
			authorDescription: t(`homepage.comments.${name}.authorDescription`),
			brandImage: `/brands/${name}.png`,
			brandBackgroundName: t(`homepage.comments.${name}.brandBackgroundName`),
			metrics: []
		}

		//@ts-ignore
		if (t.has(`homepage.comments.${name}.metrics.0.value`)) {
			commentObject.metrics.push(
				{
					value: t(`homepage.comments.${name}.metrics.0.value`),
					description: t(`homepage.comments.${name}.metrics.0.description`),
					suffix: t(`homepage.comments.${name}.metrics.0.suffix`)
				}
			)
		}
		//@ts-ignore
		if (t.has(`homepage.comments.${name}.metrics.1.value`)) {
			commentObject.metrics.push(
				{
					value: t(`homepage.comments.${name}.metrics.1.value`),
					description: t(`homepage.comments.${name}.metrics.1.description`),
					suffix: t(`homepage.comments.${name}.metrics.1.suffix`)
				}
			)
		}
		if (name == "hepsiburada") {
			commentObject = {
				...commentObject,
				className: "!text-base xl:!text-2xl"
			}
		}
		if (name == "anadolubank") {
			commentObject = {
				...commentObject,
				className: "!text-base xl:!text-2xl"
			}
		}
		if (name == "unitedpayment") {
			commentObject = {
				...commentObject,
				className: "!text-base xl:!text-2xl"
			}
		}
		if (name == "pazarama") {
			commentObject = {
				...commentObject,
				className: "!text-sm xl:!text-xl"
			}
		}
		return commentObject;
	})

	const brands: brandName[] = ["akode", "anadolubank", "misyon", "dgpays", "flogroup", "hepsiburada", "hepsipay", "hepsifinans", "moka", "nurolbank", "pazarama", "unitedpayment", "moneypay", "papara", "sipay", "papel", "moneytolia", "colendi", "goldenglobal", "hayatfinans", "halkode", "bpn"];

	return (
		<div className={"flex flex-col items-center"}>
			<HeaderContainer/>
			<HeroComponent title={t("homepage.hero.title")}
			               subtitle={t("homepage.hero.subtitle")}
			               description={t("homepage.hero.description")}
			               buttonTitle={t("homepage.hero.buttonTitle")} buttonHref={"#contact-us"} imageName={"homepage"}
			               brands={brands}/>
			<HepsiburadaComponent/>
			<SolutionComponent/>
			<BrandCommentsContainer comments={comments}/>
			<IndustryComponent/>
			<FirstGlanceComponent/>
			<HomepageFeatureContainer/>
			<TryNowComponent title={t("tryNow.title")}
			                 description={t("tryNow.description")}
			                 buttonHref={"#contact-us"}
			                 buttonTitle={t("tryNow.buttonTitle")}
			/>
			<ContactUsComponent/>
			<HomepageBlogContainer/>
			<FooterComponent/>
		</div>
	);
}
