import {BrandComment} from "@/components/brand-comments/BrandCommentItemV2";
import Image from "next/image";
import {GenericMetricCounter} from "@/components/generic/GenericMetricCounter";

export default function BrandCommentCompanyItem({
	                                                comment,
	                                                showMetrics = false,
	                                                className
                                                }: BrandCommentItemProps) {
	return (
		<div
			className={`text-text_dark justify-between bg-100% text-2xl flex flex-col p-5 rounded-lg md:rounded-xl gap-3 w-full h-96 md:w-[254px] md:h-[254px] text-start ${comment.brandBackgroundName || "bg-comments-bg"} ${comment.className} ${className}`}>
			<Image src={comment.brandImage}

			       alt={comment.author}
			       width={95}
			       height={20}
			       className={"!w-[95px] !h-auto"}
			/>
			{showMetrics && comment.metrics.length > 0 &&
				<div className={"flex flex-row text-white gap-7"}>
					{
						comment.metrics.map((metric) => {
							return <div>
								<GenericMetricCounter value={Number(metric.value)} suffix={metric.suffix} fontSize={32}
								                      containerClassName={"!gap-x-0"}/>
								<p className={"text-xs font-light"}>{metric.description}</p>
							</div>
						})
					}
				</div>}

		</div>
	)
}

export interface BrandCommentItemProps {
	comment: BrandComment,
	showMetrics?: boolean,
	className?: string
}
