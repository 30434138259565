'use client'
import {GenericContainer} from "@/components/generic/GenericContainer";
import {GenericTitle} from "@/components/generic/GenericTitle";
import {useTranslations} from "next-intl";
import FirstGlanceItem from "@/components/first-glance/FirstGlanceItemV2";
import {useRef} from "react";

export function FirstGlanceComponent() {
	const t = useTranslations('');
	const containerRef = useRef(null);

	return (
		<GenericContainer className={"bg-white justify-center text-center grid gap-10 md:gap-[52px]"}>
			<GenericTitle title={t("formicaWithNumbers.title")} description={t("formicaWithNumbers.description")} descriptionClassName={"whitespace-pre-wrap xl:whitespace-normal"}/>
			<GenericContainer className={" md:!grid-cols-2 grid gap-4 md:!gap-5 xl:!gap-12 !px-0 !py-0 max-w-container"}>
				<FirstGlanceItem
					title={t("formicaWithNumbers.features.feature1.title")}
					description={t("formicaWithNumbers.features.feature1.subtitle")}
					theme={"dark"}
					containerClassName={"order-1 md:order-1"}
					counterProps={{
						containerRef: containerRef,
						prefix: t("formicaWithNumbers.features.feature1.prefix"),
						value: parseInt(t("formicaWithNumbers.features.feature1.value")) || undefined,
						suffix: t("formicaWithNumbers.features.feature1.suffix")
					}}
				/>
				<FirstGlanceItem
					title={t("formicaWithNumbers.features.feature2.title")}
					description={t("formicaWithNumbers.features.feature2.subtitle")}
					theme={"light"}
					containerClassName={"order-2 md:order-2"}
					counterProps={{
						containerRef: containerRef,
						prefix: t("formicaWithNumbers.features.feature2.prefix"),
						value: parseInt(t("formicaWithNumbers.features.feature2.value")) || undefined,
						suffix: t("formicaWithNumbers.features.feature2.suffix")
					}}
				/>
				<FirstGlanceItem
					title={t("formicaWithNumbers.features.feature3.title")}
					description={t("formicaWithNumbers.features.feature3.subtitle")}
					theme={"light"}
					containerClassName={"order-4 md:order-3"}
					counterProps={{
						containerRef: containerRef,
						prefix: t("formicaWithNumbers.features.feature3.prefix"),
						value: parseInt(t("formicaWithNumbers.features.feature3.value")) || undefined,
						suffix: t("formicaWithNumbers.features.feature3.suffix")
					}}
				/>
				<FirstGlanceItem
					title={t("formicaWithNumbers.features.feature4.title")}
					description={t("formicaWithNumbers.features.feature4.subtitle")}
					theme={"dark"}
					containerClassName={"order-3 md:order-4"}
					counterProps={{
						containerRef: containerRef,
						prefix: t("formicaWithNumbers.features.feature4.prefix"),
						value: parseInt(t("formicaWithNumbers.features.feature4.value")) || undefined,
						suffix: t("formicaWithNumbers.features.feature4.suffix")
					}}
				/>
			</GenericContainer>
		</GenericContainer>
	)
}
