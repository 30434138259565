import Image from "next/image";
import {GenericContainer} from "@/components/generic/GenericContainer";
import React from "react";
import GenericButton from "@/components/generic/GenericButton";
import classNames from "classnames";

export default function IndustryFeatureComponent({
	                                                 image,
	                                                 topTitle,
	                                                 title,
	                                                 subtitle,
	                                                 description,
	                                                 button1Title,
	                                                 button2Title,
	                                                 button2Href,
	                                                 button1Href,
	                                                 reverse,
	                                                 containerClassName,
	                                                 cardView,
	                                                 customChildren
                                                 }: IndustryFeatureComponentProps) {
	return (
		<GenericContainer
			className={classNames(
				reverse ? "bg-company-video-bg bg-no-repeat bg-100% " : "bg-white",
				reverse ? "flex-col-reverse md:flex-row-reverse xl:flex-row-reverse" : "flex-col md:flex-row xl:flex-row",
				cardView && "relative !bg-transparent bg-no-repeat bg-cover flex !shadow-none  rounded-lg md:rounded-xl !w-[335px] !min-w-[335px] !h-[440px] !min-h-[440px] md:!w-[688px] md:!min-w-[688px] md:!h-[440px] md:max-w-[688px] xl:!w-[760px] xl:!min-w-[760px] xl:!h-[440px] xl:max-w-[760px] !px-0 xl:!px-14 !py-0 !pt-4 md:!pt-0 xl:!pt-5 items-start !justify-start",
				`min-h-max flex md:text-start xl:text-start items-center gap-1 xl:gap-0 gap-x-12 ${containerClassName}`)}>
			<div
				className={classNames("z-10 flex flex-col max-w-[304px] md:max-w-[431px] justify-start items-start gap-2 md:gap-3 xl:gap-0 md:items-start",
					cardView && "md:pl-20 xl:pl-0")}>
				<div className={"mb-3 md:mb-5"}>
					{topTitle && <p className={"text-xs md:text-base xl:text-lg pb-3 font-normal text-formica"}>{topTitle}</p>}
					{title && <p className={"text-2xl md:text-4xl xl:text-4.5xl pb-0 xl:pb-1 font-semibold text-formica_dark"}>{title}</p>}
					{subtitle && <p className={"text-xl xl:text-2xl font-light text-text_dark"}>{subtitle}</p>}
					{description &&
						<p className={
							classNames("text-text_light font-light text-xs md:text-base xl:text-lg mt-3 xl:mt-5", cardView && "max-w-[306px]")}>{description}</p>}
				</div>
				<div className={"flex w-full items-start justify-start gap-x-4"}>
					{button1Title && button1Href &&
						<GenericButton href={button1Href} title={button1Title} aClassName={"text-base font-medium"}
						               variant={"gradientBlue"}/>}
					{button2Title && button2Href &&
						<GenericButton icon={"learn_more_icon"} componentSize={"sm"} href={button2Href} title={button2Title} variant={"gradientBlue"}/>}
				</div>
				{customChildren && customChildren}
			</div>
			{image && !cardView &&
				<div className={"flex items-center"}>
					<Image src={image} alt={title || "Feature Image"} width={361} height={443} className={"z-0"}/>
				</div>}
		</GenericContainer>
	)
}

export interface IndustryFeatureComponentProps {
	image?: string,
	topTitle?: string,
	title?: string,
	icon?: string,
	subtitle?: string,
	description?: string,
	button1Title?: string,
	button1Href?: string,
	button2Title?: string,
	button2Href?: string,
	reverse?: boolean,
	containerClassName?: string,
	key?: string | number,
	cardView?: boolean,
	customChildren?: React.ReactNode
}
