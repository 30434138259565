import Image from "next/image";
import {Blog} from "@/domain/Blog";
import {useRouter} from 'next/navigation'
import classNames from "classnames";
import {Document} from "@/domain/Document";
import {useTranslations} from "next-intl";

export default function BlogItem({blog, imageContainerClassName}: BlogItemProps) {
	const t = useTranslations('');
	const router = useRouter()
	const href = blog.resources && blog.resources.length > 0 && blog.resources.includes("eBook") ? `/e-books/${blog.slug}` : `/blog/${blog.slug}`
	const onContainerClick = (e: any) => {
		router.push(href)
	}

	return (
		<a key={blog.name}
		   href={href}
		     className={"flex flex-col w-full h-full hover:cursor-pointer"}
		     onClick={onContainerClick}>
			{blog.image &&
				<div className={"mb-3"}>
					<Image src={blog.image} alt={blog.name || "Blog Image"} width={0} height={0}
					       className={classNames("w-full aspect-[16/9] rounded-xl", imageContainerClassName)}
					/>
				</div>
			}
			<div className={"flex flex-row gap-3 items-center pb-2"}>
				{blog.resources && blog.resources.length > 0 &&
					<p className={"text-[#C57500] text-sm font-light rounded-full px-2.5 py-0.5 bg-[#FCF1BB]"}>
						{blog.resources[0].includes("eBook") ? t("ebook") : blog.resources[0]}
					</p>}
				{blog.createdDate &&
					<p className={"text-text_light text-sm font-medium"}>
						{new Date(blog.createdDate).toLocaleDateString()}
					</p>}
			</div>
			<div className={"flex flex-col gap-1 items-start text-left"}>
				{blog.name &&
					<p className={"text-text_dark text-base font-medium line-clamp-2"}>
						{blog.name}
					</p>}
				{blog.summary &&
					<p className={"text-text_light text-sm font-light line-clamp-2"}>
						{blog.summary}
					</p>}
			</div>
		</a>
	)
};

export interface BlogItemProps {
	blog: Blog | Document,
	imageContainerClassName?: string
}
