import IndustryMarqueeItem, {IndustryMarqueeItemProps} from "@/components/industry/IndustryMarqueeItem";
import {motion, useInView, useAnimation} from "framer-motion";
import {useRef, useEffect, MutableRefObject} from "react";
import {useMediaQuery} from "@/util/PageUtil";

export default function IndustryMarquee({
	                                        items,
	                                        duration = items ? items.length * 1.3 : 0,
	                                        reverse,
	                                        containerRef = useRef(null),
	                                        containerKey
                                        }: IndustryMarqueeProps) {
	const isInView = useInView(containerRef, {
		once: false,
		margin: "0px 200px 0px 0px"
	});
	const controls = useAnimation();
	const onMobile = useMediaQuery("(max-width: 767px)");

	useEffect(() => {
		if (isInView) {
			controls.start("animate")
		} else {
			controls.stop();
		}
	}, [isInView, controls]);

	useEffect(() => {
		controls.start("animate");
	}, [items]);

	const getItems = () => {
		if (!items) return [];
		if (reverse) return items?.slice().reverse();
		return items;
	};

	let animatePositions: number[] = getItems().map((_, index) => {
		const margin = 12;
		if (index === 0) return 0;
		return -((onMobile ? 60 : 80) + margin) * (index);
	});
	if (reverse) animatePositions = animatePositions?.slice().reverse();

	const marqueeVariants = {
		animate: {
			y: animatePositions,
			transition: {
				y: {
					repeat: Infinity,
					repeatType: "loop",
					duration: duration,
					ease: [0.7, 0, 0.7, 0],
					repeatDelay: 0.7
				}
			}
		}
	};

	return (
		<div ref={containerRef} className={"h-[60px] md:h-20 overflow-hidden"}>
			<motion.div
				key={containerKey}
				className={"h-[60px] md:h-20"} variants={marqueeVariants} animate={controls}>
				{items && getItems().map((item, index) => (
					<motion.div key={index}>
						<IndustryMarqueeItem className={index != 0 && "mt-3"} image={item.image} alt={item.alt}
						                     borderColorClassname={item.borderColorClassname}/>
					</motion.div>
				))}
			</motion.div>
		</div>
	);
}

export interface IndustryMarqueeProps {
	items?: IndustryMarqueeItemProps[];
	reverse?: boolean;
	containerRef?: MutableRefObject<any>;
	duration?: number;
	containerKey?: string;
}
