import {motion} from "framer-motion";
import classNames from "classnames";
import Image from "next/image";

export default function IndustryMarqueeItem({image, alt, borderColorClassname, className}: IndustryMarqueeItemProps
) {
	return (
		<motion.div
			className={classNames("z-10 flex items-center justify-center bg-white rounded-xl w-[160px] md:w-[189px] h-[60px] md:h-20 border-2", borderColorClassname, className)}>
			<Image src={image}
			       alt={alt ?? "Image"}

			       width={75} height={20}/>
		</motion.div>
	)

}


export interface IndustryMarqueeItemProps {
	image?: string,
	alt?: string,
	borderColorClassname?: string,
	className?: string
}
