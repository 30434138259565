'use client'
import {
	BrandCommentsComponent,
	BrandCommentsComponentProps
} from "@/components/brand-comments/BrandCommentsComponentV2";

export function BrandCommentsContainer({comments}: BrandCommentsComponentProps) {

	return (
		<BrandCommentsComponent comments={comments}/>
	)
}
