import React, {Component} from 'react';
import Icon from "@/components/icon/Icon";

export interface CustomPaginatorProps {
  rows: number;
  page: number;
  pageCount: number;
  sort?: DataTableSortMeta[]
}

export interface DataTableSortMeta {
  field: string;
  order: DataTableSortOrderType;
}

export type DataTableSortOrderType = 1 | 0 | -1 | undefined | null;

class CustomPaginator extends Component<Props, State> {
  public state = new State();

  public render() {
    const {rowsPerPageOptions, rows, totalRecords, currentPage, onPageChange, fixed, borderHidden, loading} = this.props;
    const {totalPages} = this.state;

    const renderPages = [];
    for (let i = currentPage - 4; i <= currentPage + 4; i++) {
      if (i > 0 && i <= totalPages) {
        renderPages.push(
          <div
            onClick={() => !loading && onPageChange({rows, page: i, pageCount: totalPages})}
            key={i}
            className={`w-10 h-10 hover:cursor-pointer flex items-center justify-center rounded-full ${
              currentPage === i ? "bg-[#EBFBFF]" : "bg-transparent"
            } ${loading ? "appearance-none" : ""}`}
          >
            {i}
          </div>
        );
      }
    }

    return (
      totalRecords > 0 &&
      totalRecords !== undefined && (
        <div className={`w-full ${fixed ? "mt-2" : "fixed"} bottom-0.5 bg-white`}>
          <div
            className={`flex w-full font-formica items-center justify-center relative py-4`}>
            <div className={`flex gap-x-4 items-center justify-center ${fixed ? "" : "mr-32"}`}>
              <div className={`cursor-pointer`} onClick={this.handleStartClick}>
                <Icon icon={"arrow2-left"} size={16}/>
              </div>
              <div className={`${this.canGoPrevious() ? "hover:cursor-pointer" : ""}`} onClick={this.handlePrevClick}>
                <Icon icon={"arrow1-left"} size={16}/>
              </div>
              <div className={"flex gap-x-3 items-center justify-center"}>{renderPages}</div>
              <div className={`${this.canGoNext() ? "hover:cursor-pointer" : ""}`} onClick={this.handleNextClick}>
                <Icon icon={"arrow1"} size={16}/>
              </div>
              <div className={`cursor-pointer`} onClick={this.handleEndClick}>
                <Icon icon={"arrow2"} size={16}/>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const {rows, totalRecords, currentPage} = this.props;
    if (prevProps.currentPage !== currentPage) {
      this.setState({totalPages: Math.ceil(totalRecords / rows)});
    }
    if (prevProps.rows !== rows || prevProps.totalRecords !== totalRecords) {
      const totalPages = Math.ceil(totalRecords / rows);
      this.setState({totalPages});
    }
    if (prevProps.currentRecords != 0 && this.props.currentRecords == 0 && currentPage > 1) {
      this.props.onPageChange({rows: rows, page: currentPage - 1, pageCount: this.state.totalPages});
    }
  }

  public componentDidMount() {
    const {rows, totalRecords, currentPage} = this.props;
    this.setState({totalPages: Math.ceil(totalRecords / rows)});
  }

  private handleNextClick = () => {
    const {currentPage, onPageChange, rows} = this.props;
    const {totalPages} = this.state;
    if (this.canGoNext()) {
      onPageChange({rows: rows, page: currentPage + 1, pageCount: totalPages});
    }
  };

  private handlePrevClick = () => {
    const {currentPage, onPageChange, rows} = this.props;
    const {totalPages} = this.state;
    if (this.canGoPrevious()) {
      onPageChange({rows: rows, page: currentPage - 1, pageCount: totalPages});
    }
  };

  private handleStartClick = () => {
    const {onPageChange, rows} = this.props;
    const {totalPages} = this.state;
    onPageChange({rows: rows, page: 1, pageCount: totalPages});
  };

  private handleEndClick = () => {
    const {onPageChange, rows} = this.props;
    const {totalPages} = this.state;
    onPageChange({rows: rows, page: totalPages, pageCount: totalPages});
  };

  private canGoNext = () => {
    const {totalPages} = this.state;
    const {currentPage, loading} = this.props;
    return currentPage <= totalPages - 1 && !loading
  }

  private canGoPrevious = () => {
    const {currentPage, loading} = this.props;
    return currentPage > 1 && !loading
  }
}

interface Props {
  rows: number;
  totalRecords: number;
  currentRecords?: number;
  rowsPerPageOptions: number[];
  onPageChange: (event: CustomPaginatorProps) => void;
  currentPage: number;
  fixed?: boolean;
  borderHidden?: boolean;
  loading?: boolean;
}

class State {
  totalPages = 0;
}

export default CustomPaginator;
