'use client'
import {GenericContainer} from "@/components/generic/GenericContainer";
import BrandCommentItem, {BrandComment, BrandCommentItemProps} from "@/components/brand-comments/BrandCommentItemV2";
import {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css/bundle';
import {GenericTitle} from "@/components/generic/GenericTitle";
import {useTranslations} from "next-intl";
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper/modules";
import BrandCommentCompanyItem from "@/components/brand-comments/BrandCommentCompanyItem";
import Icon from "@/components/icon/Icon";

export function BrandCommentsComponent({comments}: BrandCommentsComponentProps) {
	const [selectedComment, setSelectedComment] = useState<BrandComment>(comments[0])
	const t = useTranslations('aboutUs');

	useEffect(() => {
		setSelectedComment(comments[0])
	}, [comments]);
	const onClick = (comment: BrandComment) => {
		setSelectedComment(comment)
	}

	return (
		<GenericContainer className={"bg-[#F3F5F7] justify-start text-center flex flex-col gap-10 xl:gap-[52px] h-[612px]"}>
			<GenericTitle title={t("customerTestimonials.title")} description={t("customerTestimonials.description")} descriptionClassName={"whitespace-pre-wrap md:whitespace-normal"}/>
			<Swiper
				className={"w-full md:h-[326px]"}
				modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
				slidesPerView={1}
				autoplay={{delay: 10000}}
				effect={'slide'}
				navigation={{
					nextEl: '.swiper-next',
					prevEl: '.swiper-prev'
				}}
				centeredSlides
				pagination={{clickable: true, bulletActiveClass: "swiper-pagination-bullet-active !bg-formica_dark relative", el: ".swiper-pagination"}}
				loop={true}>
				{
					comments.map((comment: BrandComment) => {
						return (
							<SwiperSlide key={comment.author} className={"!flex flex-col md:flex-row items-start justify-center gap-2 md:gap-5"}>
								<BrandCommentCompanyItem comment={comment}/>
								<BrandCommentItem comment={comment}/>
							</SwiperSlide>
						)
					})
				}
				<div className={"flex flex-row justify-center gap-x-[156px] mt-2  md:-mt-[45px]"}>
					<div className={"swiper-pagination"}/>
					<div className={"swiper-prev z-10 rounded-full bg-white w-10 h-10 flex items-center align-middle justify-center fill-formica_dark"}><Icon icon={"arrow1-left"} size={24}/></div>
					<div className={"swiper-next z-10 rounded-full bg-white w-10 h-10 flex items-center align-middle justify-center fill-formica_dark"}><Icon icon={"arrow1"} size={24}/></div>
				</div>
			</Swiper>
		</GenericContainer>
	)
}

export interface BrandCommentsComponentProps {
	comments: BrandComment[],
}
