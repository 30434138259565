'use client'
import React, {MutableRefObject, ReactElement, useEffect, useRef} from "react";
import {motion, useInView, Variants} from "framer-motion";
import {useMediaQuery} from "@/util/PageUtil";

export default function FeaturePopupComponent({
	                                              id,
	                                              component,
	                                              x,
	                                              mobileX,
	                                              tabletX,
	                                              y,
	                                              mobileY,
	                                              tabletY,
	                                              containerRef = useRef(null)
                                              }: FeaturePopupComponentProps) {
	const onMobile = mobileY && mobileX && useMediaQuery("(max-width: 767px)");
	const onTablet = mobileY && mobileX && useMediaQuery("(max-width: 1281px)");
	const isInView = useInView(containerRef, {
		once: true,
		margin: "0px 0px -400px 0px"
	});

	const variants: Variants = {
		animate: {
			scale: 1,
			transition: {
				duration: 0.5,
				delay: id as number * 0.5
			}
		},
		initial: {
			scale: 0,
		},
		position: {
			top: (onMobile && mobileY) || (onTablet && tabletY) || (!(onMobile && onTablet) && y),
			left: (onMobile && mobileX) || (onTablet && tabletX) || (!(onMobile && onTablet) && x),
		}
	};

	return (
		<motion.div
			custom={id}
			key={id}
			variants={variants}
			initial={["initial", "position"]}
			animate={isInView ? ["animate", "position"] : ["initial", "position"]}
			className="absolute scale-0"
		>
			{component}
		</motion.div>
	);

}

export interface FeaturePopupComponentProps {
	id?: string | number,
	component?: ReactElement,
	x: number,
	mobileX?: number,
	tabletX?: number,
	y: number,
	mobileY?: number,
	tabletY?: number,
	containerRef?: MutableRefObject<any>
}
