import classNames from "classnames";
import {GenericMetricCounter, GenericMetricCounterProps} from "@/components/generic/GenericMetricCounter";
import Image from "next/image";

export default function FirstGlanceItem({
	                                        title,
	                                        description,
	                                        href,
	                                        theme = "dark",
	                                        counterProps,
	                                        containerClassName
                                        }: FirstGlanceItemProps
) {

	return (
		<div
			className={classNames("relative flex-col whitespace-pre-wrap shadow-card gap-2 md:gap-4 p-4 md:p-6 xl:p-7 w-full h-full items-start text-left flex justify-around rounded-xl", theme == "dark" ? "bg-formica-with-numbers-dark-gradient" : "bg-white", containerClassName)}>
			<p
				className={classNames("text-base md:text-1.5xl xl:text-2.5xl font-medium text-text_dark", theme == "dark" ? "text-white" : "text_dark")}>
				{title}
			</p>
			<p
				className={classNames("text-xs md:text-sm xl:text-base font-light text-text_light", theme == "dark" ? "text-white opacity-80" : "text-text_light opacity-80")}>
				{description}
			</p>
			{counterProps && counterProps.value && <GenericMetricCounter
				containerClassName={classNames(theme == "dark" ? "text-white" : "text-formica_dark")}
				{...counterProps} />}
			<Image className={"absolute w-20 md:w-[100px] right-0 bottom-0"}
			       src={theme == "dark" ? "/first-glance/glance_dark.svg" : "/first-glance/glance_light.svg"} alt={title}
			       width={100} height={100}/>
		</div>
	)

}

export interface FirstGlanceItemProps {
	title: string,
	description: string,
	href?: string,
	theme?: "light" | "dark"
	counterProps?: GenericMetricCounterProps,
	containerClassName?: string
}

