import Image from "next/image";
import {Blog} from "@/domain/Blog";
import {useRouter} from 'next/navigation'
import classNames from "classnames";
import {Document} from "@/domain/Document";
import {useTranslations} from "next-intl";

export default function BlogItem({blog, imageContainerClassName}: BlogItemProps) {
	const t = useTranslations('');
	const router = useRouter()
	const onContainerClick = (e: any) => {
		if (blog.resources && blog.resources.length > 0 && blog.resources.includes("eBook")) {
			router.push(`/e-books/${blog.slug}`)
			return;
		}
		router.push(`/blog/${blog.slug}`)
	}

	return (
		<div key={blog.name}
		     className={"group flex flex-col w-full h-full hover:cursor-pointer shadow-blog-card rounded-b-2xl"}
		     onClick={onContainerClick}>
			{blog.image &&
				<div>
					<Image src={blog.image} alt={blog.name || "Blog Image"} width={0} height={0}
					       className={classNames("w-full aspect-[16/9] rounded-t-2xl", imageContainerClassName)}
					/>
				</div>
			}
			<div className={"flex flex-col gap-4 p-5"}>
				<div className={"flex flex-row gap-3 items-center"}>
					{blog.resources && blog.resources.length > 0 &&
						<p className={"text-[#C57500] text-sm font-light rounded-full px-2.5 py-0.5 bg-[#FCF1BB]"}>
							{blog.resources[0].includes("eBook") ? t("ebook") : blog.resources[0]}
						</p>}
					{blog.createdDate &&
						<p className={"text-text_light text-xs font-light"}>
							{new Date(blog.createdDate).toLocaleDateString()}
						</p>}
				</div>
				<div className={"flex flex-col gap-1 items-start text-left"}>
					{blog.name &&
						<p className={"text-text_dark text-lg font-normal line-clamp-2 group-hover:underline"}>
							{blog.name}
						</p>}
				</div>
			</div>
		</div>
	)
};

export interface BlogItemProps {
	blog: Blog | Document,
	imageContainerClassName?: string
}
