"use client"
import {motion, useInView, UseInViewOptions} from "framer-motion";
import classNames from "classnames";
import {MutableRefObject, useRef} from "react";

const getItems = (value: GenericMetricCounterProps["value"], animateValueCount: GenericMetricCounterProps["animateValueCount"]) => {
	if (!value) return [];
	return Array.from({length: animateValueCount}, (_, index) => {
		if (index === 0) return 0;
		if (index === animateValueCount - 1) return value;
		return Math.round(value / animateValueCount * index);
	});
}

export function GenericMetricCounter({
	                                     value,
	                                     prefix,
	                                     suffix,
	                                     fontSize = 60,
	                                     containerClassName,
	                                     animateValueCount = 4,
	                                     animationDuration = 0.3,
	                                     containerRef = useRef(null),
	                                     inViewConfig = { once: true, margin: "0px 200px -50px 0px" }
                                     }: GenericMetricCounterProps) {
	const padding = fontSize <= 16 ? fontSize / 2 : 16;
	const height = fontSize + padding;
	const isInView = useInView(containerRef, inViewConfig);
	const items: number[] = getItems(value, animateValueCount);
	let animatePositions = items.map((_, index) => {
		const margin = 0;
		return -(height * index) + (padding / 2 * (index - 1)) - margin;
	});

	const marqueeVariants = {
		animate: {
			y: animatePositions,
			transition: {
				y: {
					duration: animationDuration,
					ease: "linear",
				}
			},
			transitionEnd: {
				height: fontSize
			}
		}
	};

	return (
		<div
			className={classNames("overflow-hidden font-medium flex flex-row gap-x-2", `text-[${fontSize}px]`, `h-[${fontSize}px]`, containerClassName && containerClassName)}
			ref={containerRef}>
			{prefix && <motion.h1 className={"flex flex-col justify-center"}>{prefix}</motion.h1>}
			<motion.div className={`-translate-y-[${padding / 2}px]`} variants={marqueeVariants}
			            animate={isInView ? "animate" : "stop"}>
				{items && items.map((item, index) => (
					<motion.div key={index} className={classNames(index != 0 && `my-[${padding / 2}px]`, `h-[${fontSize}px]`)}>
						<NumberItem value={item} classname={`h-[${height}px]`}/>
					</motion.div>
				))}
			</motion.div>
			{suffix && <motion.h1 className={"flex flex-col justify-center"}>{suffix}</motion.h1>}
		</div>
	);
}

const NumberItem = ({value, classname}: { value: number, classname?: string }) => {
	return (
		<motion.div
			className={classNames("tabular-nums z-10 flex items-center justify-center", classname)}>
			{value}
		</motion.div>
	);
}

export interface GenericMetricCounterProps {
	value: number,
	countdown?: boolean
	direction?: "up" | "down"
	prefix?: string
	suffix?: string
	fontSize?: number
	containerClassName?: string,
	animateValueCount?: number
	animationDuration?: number
	containerRef?: MutableRefObject<any>
	inViewConfig?: UseInViewOptions
}
