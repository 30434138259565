import { GenericMetricCounter } from "@/components/generic/GenericMetricCounter";
import { useMediaQuery } from "@/util/PageUtil";

function calculateValue(onMobile: boolean, onTablet: boolean, metricsValue: number): number {
	const baseSize = (onMobile && 40) || (onTablet && 52) || 60;
	const adjustedSize = baseSize / (metricsValue > 100 ? 1.2 : 1);
	return parseFloat(adjustedSize.toFixed(0));
}

export default function GenericMetricCard({
	                                          metrics
                                          }: GenericMetricCardProps) {

	const onTablet = useMediaQuery("(max-width: 1281px)");
	const onMobile = useMediaQuery("(max-width: 767px)");

	return (
		<div
			className={"z-20 flex flex-row w-full xl:flex-col items-center xl:items-start gap-x-4 md:gap-x-2 justify-center p-4 md:p-6 bg-white rounded-xl xl:w-[260px] xl:h-[340px] shadow-card"}>
			{metrics && metrics.value &&
				<GenericMetricCounter value={Number(metrics.value)} suffix={metrics.suffix} prefix={metrics.prefix} fontSize={calculateValue(onMobile, onTablet, Number(metrics.value))} containerClassName={"text-formica_dark font-semibold !gap-x-0 min-w-fit"} />
			}
			{metrics && metrics.title &&
				<p className={"text-text_light font-light text-xs md:text-sm xl:text-base xl:mt-5"}>{metrics.title}</p>}
		</div>
	)
}

export interface GenericMetricCardProps {
	metrics?: {
		title: string,
		value: string,
		prefix?: string,
		suffix?: string,
	}
}
